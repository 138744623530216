import React, { useState, useEffect } from "react";
import { CampaignContext } from "./index";

const CampaignContextProvider = ({ children, value }) => {
    const [campaignAstreaValue, setCampaignAstreaValue] = useState(value || 'none')
    const [campaignThemisValue, setCampaignThemisValue] = useState(value || 'none')

    useEffect(()=>{
        let getItemSessionAstrea = localStorage.getItem('campaign_astrea:1.0.0')
        let getItemSessionThemis = localStorage.getItem('campaign_themis:1.0.0')

        const hasAstreaSessionItem = value !== getItemSessionAstrea && getItemSessionAstrea != null
        const hasThemisSessionItem = value !== getItemSessionThemis && getItemSessionThemis != null

        if (hasAstreaSessionItem) {
            setCampaignAstreaValue(getItemSessionAstrea)
        }

        if (hasThemisSessionItem) {
            setCampaignThemisValue(getItemSessionThemis)
        }
    },[])

    return (
        <CampaignContext.Provider value={{
            campaignAstrea: campaignAstreaValue,
            campaignThemis: campaignThemisValue
        }}>
            {children}
        </CampaignContext.Provider>
    )
}

export default CampaignContextProvider